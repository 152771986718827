<!-- 结算按钮 -->
<template>
  <!-- <van-submit-bar :price="getPrice()" :disabled="canBy" :button-text="text" @submit="onSubmit">
  </van-submit-bar> -->

  <div
    style="background-color: white ;height:4rem;line-height: 4rem; position: absolute;bottom: 0; width: 100%; display: flex;flex-direction: row;justify-content: space-between;">
    <div>
      <span style="margin-left: 0.5rem;color: #666;"> 合计:</span><span
        style="color:#D33B2E;font-weight: 600;font-size: 1.2rem;"> {{
          this.orderInfo.payProductTotal
        }} 点</span>
    </div>
    <div v-if="!canBy" style="background-color:#e69992 ; width: 7rem; height: 100%; color: white; text-align: center;">
      {{
          text }} </div>
    <div @click="onSubmit" v-else
      style="background-color:#D33B2E ; width: 7rem; height: 100%; color: white; text-align: center;">{{ text }} </div>
  </div>
</template>

<script>
import { Toast } from "vant";

export default {
  name: "comfirmOrderFooter",
  props: {
    orderInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
    text: {
      type: String,
      default: "结算",
    },
    canBy: {
      type: Boolean,
    },
  },
  data() {
    return {
      checked: false,
      canPut: true,
    };
  },
  watch: {
  },
  methods: {
    handleCheckedChang(checked) {
      this.$emit("check", checked);
    },
    // getPrice() {
    //   return parseFloat(this.orderInfo.productTotal) * 100
    // },
    onClickEditAddress() { },
    async onSubmit() {
      this.creatOrder()
    },
    async creatOrder() {
      Toast.loading({
        message: '加载中...',
        forbidClick: true
      });
      this.cardId = localStorage.getItem("cardId");
      this.$api
        .productOrderInfo(this.orderInfo)
        .then((res) => {
          if (res.code == 1) {
            this.$Toast(res.msg);
            return
          }
          localStorage.setItem("gooddetail", JSON.stringify(res.data));
          this.goCashier()
          Toast.clear();
        })
        .catch((err) => {
          Toast.clear();
        });
    },
    goCashier() {
      if (localStorage.getItem("entrCode") === 'E3') {
        this.$router.push({
          path: "/MovieForGoodsCashier",
          query: {
            type: "zy-shop",
            creatTime: this.$moment().format('YYYY-MM-DD HH:mm:ss')
          },
        });
      } else
        this.$router.push({
          path: "/Cashier",
          query: {
            type: "zy-shop",
            creatTime: this.$moment().format('YYYY-MM-DD HH:mm:ss')
          },
        });

    }
  },
};
</script>

<style>
.van-submit-bar__text {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
  padding-right: 3.2vw;
  color: #323233;
  text-align: left;
}
</style>
